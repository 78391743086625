import React from "react";
import styles from "./Gallery.module.scss";
import img1 from "./../../assets/images/gallery/1.jpeg";
import img2 from "./../../assets/images/gallery/2.jpeg";
import img3 from "./../../assets/images/gallery/3.jpeg";
import img4 from "./../../assets/images/gallery/4.jpeg";
import img5 from "./../../assets/images/gallery/5.jpeg";
import img6 from "./../../assets/images/gallery/6.jpeg";
import img7 from "./../../assets/images/gallery/7.jpeg";
import img8 from "./../../assets/images/gallery/8.jpeg";
import img9 from "./../../assets/images/gallery/9.jpeg";
import img10 from "./../../assets/images/gallery/10.jpeg";
import img11 from "./../../assets/images/gallery/11.jpeg";
import img12 from "./../../assets/images/gallery/12.jpeg";
import img13 from "./../../assets/images/gallery/13.jpeg";
import img14 from "./../../assets/images/gallery/14.jpeg";
import img15 from "./../../assets/images/gallery/15.jpeg";
import img16 from "./../../assets/images/gallery/16.jpeg";
import img17 from "./../../assets/images/gallery/17.jpeg";
import img18 from "./../../assets/images/gallery/18.jpeg";
import img19 from "./../../assets/images/gallery/19.jpeg";
import img20 from "./../../assets/images/gallery/20.jpeg";
import img21 from "./../../assets/images/gallery/21.jpeg";
import img22 from "./../../assets/images/gallery/22.jpeg";
import img23 from "./../../assets/images/gallery/23.jpeg";
import img24 from "./../../assets/images/gallery/24.jpeg";
import img25 from "./../../assets/images/gallery/25.jpeg";
import img26 from "./../../assets/images/gallery/26.jpeg";
import img27 from "./../../assets/images/gallery/27.jpeg";
import img28 from "./../../assets/images/gallery/28.jpeg";
import img29 from "./../../assets/images/gallery/29.jpeg";
import img30 from "./../../assets/images/gallery/30.jpeg";
import img31 from "./../../assets/images/gallery/31.jpeg";

interface PhotoType {
  src: string;
  alt: string;
}

const photos: PhotoType[] = [
  { src: img1, alt: "Image 1" },
  { src: img2, alt: "Image 2" },
  { src: img3, alt: "Image 3" },
  { src: img4, alt: "Image 4" },
  { src: img5, alt: "Image 5" },
  { src: img6, alt: "Image 6" },
  { src: img7, alt: "Image 7" },
  { src: img8, alt: "Image 8" },
  { src: img9, alt: "Image 9" },
  { src: img10, alt: "Image 10" },
  { src: img11, alt: "Image 11" },
  { src: img12, alt: "Image 12" },
  { src: img13, alt: "Image 13" },
  { src: img14, alt: "Image 14" },
  { src: img15, alt: "Image 15" },
  { src: img16, alt: "Image 16" },
  { src: img17, alt: "Image 17" },
  { src: img18, alt: "Image 18" },
  { src: img19, alt: "Image 19" },
  { src: img20, alt: "Image 20" },
  { src: img21, alt: "Image 21" },
  { src: img22, alt: "Image 22" },
  { src: img23, alt: "Image 23" },
  { src: img24, alt: "Image 24" },
  { src: img25, alt: "Image 25" },
  { src: img26, alt: "Image 26" },
  { src: img27, alt: "Image 27" },
  { src: img28, alt: "Image 28" },
  { src: img29, alt: "Image 29" },
  { src: img30, alt: "Image 30" },
  { src: img31, alt: "Image 31" },
];

const PhotoGallery: React.FC = () => {
  return (
    <div className={styles.galleryContainer}>
      {photos.map((photo, index) => (
        <div key={index} className={styles.galleryItem}>
          <img src={photo.src} alt={photo.alt} className={styles.image} />
        </div>
      ))}
    </div>
  );
};

export default PhotoGallery;
