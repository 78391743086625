import React from "react";
import style from "./LegalMentions.module.scss";

const LegalMentions: React.FC = () => {
  return (
    <div className={style.legalMentionsContainer}>
      <h1>Mentions Légales</h1>
      <section className={style.section}>
        <h2>Éditeur du site</h2>
        <p>
          <strong>Nom de l'entreprise :</strong> Sidéral
          <br />
          <strong>Numéro de TVA :</strong> BE0695158517
          <br />
          <strong>Téléphone :</strong> +32 (0)475 739 555
          <br />
          <strong>Email :</strong> patrick.wala@skynet.be
        </p>
      </section>

      <section className={style.section}>
        <h2>Hébergement</h2>
        <p>
          <strong>Hébergeur :</strong> OVH
          <br />
          <strong>Adresse :</strong> 2 Rue Kellermann, 59100 Roubaix, France
          <br />
          <strong>Téléphone :</strong> +33 9 72 10 10 07
          <br />
          <strong>Site web :</strong>{" "}
          <a
            href="https://www.ovhcloud.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.ovhcloud.com
          </a>
        </p>
      </section>

      <section className={style.section}>
        <h2>Responsabilité</h2>
        <p>
          Sidéral s’efforce de fournir des informations précises et à jour sur
          ce site. Cependant, nous ne garantissons pas l'exactitude, la
          complétude ou l'actualité des informations publiées. L'utilisateur est
          responsable de l'utilisation des informations disponibles sur ce site.
        </p>
      </section>

      <section className={style.section}>
        <h2>Propriété intellectuelle</h2>
        <p>
          Tous les contenus présents sur ce site (textes, images, vidéos, logos,
          etc.) sont protégés par les lois relatives à la propriété
          intellectuelle. Toute reproduction, représentation ou diffusion, en
          tout ou en partie, sans autorisation préalable est strictement
          interdite.
        </p>
      </section>

      <section className={style.section}>
        <h2>Protection des données</h2>
        <p>
          Les informations personnelles collectées sur ce site (via le
          formulaire de contact, par exemple) sont utilisées exclusivement dans
          le cadre de nos services. Vous avez le droit d'accéder, de corriger ou
          de supprimer vos données personnelles en nous contactant à l'adresse
          suivante : <strong>patrick.wala@skynet.be</strong>.
        </p>
      </section>

      <section className={style.section}>
        <h2>Liens externes</h2>
        <p>
          Ce site peut contenir des liens vers des sites externes. Sidéral ne
          peut être tenu responsable du contenu ou des pratiques de
          confidentialité de ces sites.
        </p>
      </section>

      <footer className={style.footer}>
        <p>Dernière mise à jour : 24 novembre 2024</p>
      </footer>
    </div>
  );
};

export default LegalMentions;
