import React, { useState, ChangeEvent, FormEvent } from "react";
import emailjs from "emailjs-com";
import live from "../../assets/images/Images/live.jpg";
import mail2 from "../../assets/images/Icones/mail2.png";
import phone2 from "../../assets/images/Icones/phone2.png";
import calendar from "../../assets/images/Icones/calendar.png";
import style from "./Contact.module.scss";

// Définition du type pour les données du formulaire
interface FormData {
  name: string;
  email: string;
  phone: string;
  eventType: string;
  eventDate: string;
  eventLocation: string;
  services: string[];
  message: string;
}

const Contact: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    eventType: "",
    eventDate: "",
    eventLocation: "",
    services: [],
    message: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "services") {
      setFormData({
        ...formData,
        services: formData.services.includes(value)
          ? formData.services.filter((service) => service !== value)
          : [...formData.services, value],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Formater les services en une chaîne de caractères
    const formattedServices = formData.services.join(", ");

    // Préparer les données pour EmailJS
    const emailData = {
      ...formData,
      services: formattedServices, // Remplacer le tableau par une chaîne
    };

    emailjs
      .send(
        "service_24b4okn",
        "template_us1qzk5",
        emailData,
        "4X7zvOwIdoUwuMyj4"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Votre demande a été envoyée avec succès !");
        },
        (error) => {
          console.log(error.text);
          alert("Erreur lors de l'envoi du formulaire.");
        }
      );
  };

  return (
    <div className={style.contactContainer}>
      <div className={style.imgContainer}>
        <img src={live} alt="Live Event" />
      </div>
      <div className={style.contact2Container}>
        <h2>Comment nous contacter ?</h2>
        <div className={style.iconRow}>
          <div className={style.realisationContainer}>
            <div className={style.iconContainer}>
              <img src={mail2} alt="Mail" />
            </div>
            <h3>Contact initial par email</h3>
            <p>
              Afin de mieux comprendre vos besoins, nous vous invitons à nous
              contacter par email en détaillant votre projet. Pour cela,
              précisez le type d’événement, la date, le lieu, et les équipements
              souhaités (son, éclairage, scène, etc.). Plus vos informations
              sont précises, mieux nous pourrons vous conseiller !
            </p>
          </div>
          <div className={style.realisationContainer}>
            <div className={style.iconContainer}>
              <img src={phone2} alt="Phone" />
            </div>
            <h3>Suivi personnalisé : Appel de notre équipe</h3>
            <p>
              Une fois votre demande reçue, l’un de nos conseillers vous
              rappellera dans les plus brefs délais. Cet échange nous permettra
              de clarifier tous les détails et de répondre à vos questions. Nous
              pourrons ainsi mieux comprendre vos attentes et vous proposer des
              solutions adaptées.
            </p>
          </div>
          <div className={style.realisationContainer}>
            <div className={style.iconContainer}>
              <img src={calendar} alt="Calendrier" />
            </div>
            <h3>Prise de rendez-vous</h3>
            <p>
              Enfin, pour garantir une préparation optimale, nous organiserons
              un rendez-vous. Ce dernier pourra se faire par téléphone ou sur
              place, en fonction de vos préférences et de l’ampleur du projet.
              Ensemble, nous finaliserons les détails logistiques pour un
              événement parfaitement orchestré.
            </p>
          </div>
        </div>
        <div className={style.formContainer}>
          <div className={style.formText}>
            <h3>Nous sommes là pour vous aider !</h3>
            <p>
              Remplissez le formulaire pour nous donner une idée de votre
              projet. Nos conseillers vous répondront rapidement pour discuter
              de vos besoins spécifiques et vous aider à créer un événement
              mémorable.
            </p>
          </div>
          <form onSubmit={handleSubmit} className={style.contactForm}>
            <h3>Formulaire de contact</h3>
            <div className={style.input}>
              <label>
                Nom et Prénom
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className={style.input}>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className={style.input}>
              <label>
                Numéro de téléphone
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className={style.input}>
              <label>
                Type d'événement
                <input
                  type="text"
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className={style.input}>
              <label>
                Date de l’événement
                <input
                  type="date"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className={style.input}>
              <label>
                Lieu de l’événement
                <input
                  type="text"
                  name="eventLocation"
                  value={formData.eventLocation}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>

            <div className={style.input}>
              <label>
                Services souhaités :
                <div className={style.checkboxContainer}>
                  <label className={style.checkboxItem}>
                    <span>Sonorisation</span>
                    <input
                      type="checkbox"
                      name="services"
                      value="Sonorisation"
                      onChange={handleChange}
                    />
                  </label>
                  <label className={style.checkboxItem}>
                    <span>Éclairage</span>
                    <input
                      type="checkbox"
                      name="services"
                      value="Éclairage"
                      onChange={handleChange}
                    />
                  </label>
                  <label className={style.checkboxItem}>
                    <span>Scène</span>
                    <input
                      type="checkbox"
                      name="services"
                      value="Scène"
                      onChange={handleChange}
                    />
                  </label>
                  <label className={style.checkboxItem}>
                    <span>DJ/Animation</span>
                    <input
                      type="checkbox"
                      name="services"
                      value="DJ/Animation"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </label>
            </div>

            <div className={style.input}>
              <label>
                Message ou détails supplémentaires
                <textarea
                  name="message"
                  rows={3}
                  value={formData.message}
                  onChange={handleChange}
                />
              </label>
            </div>

            <button type="submit">Envoyer la demande</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
