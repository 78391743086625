import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Homepage from "./pages/homepage/Homepage";
import Contact from "./pages/contact/Contact";
import Dj from "./pages/DJ/Dj";
import Sono from "./pages/sono/Sono";
import Servic from "./pages/servic/Servic";

import LegalMentions from "./pages/LegalMentions/LegalMentions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/dj",
        element: <Dj />,
      },
      {
        path: "/sono",
        element: <Sono />,
      },
      {
        path: "/services",
        element: <Servic />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/LegalMentions ",
        element: <LegalMentions />,
      },
    ],
  },
]);
