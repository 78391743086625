import style from "./../Materiel.module.scss";

function Lumiere() {
  return (
    <div className={style.descriptMat}>
      <li>Lumière (Scan, wash, lyre,... )</li>
      <li>Laser</li>
      <li>Stromboscope</li>
      <li>Lampe UV</li>
      <li>Blinder</li>
    </div>
  );
}

export default Lumiere;
