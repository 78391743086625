import styles from "./Services.module.scss";
import { Link } from "react-router-dom";
import icone1 from "../../assets/images/Icones/dj.png";
import icone2 from "../../assets/images/Icones/scene.png";
import icone3 from "../../assets/images/Icones/transport.png";

const servicesData = [
  {
    title: "Dj",
    description:
      "Faites de votre événement un moment inoubliable avec nos services DJ sur mesure. Que ce soit pour un mariage, une soirée privée, un anniversaire, ou un événement d’entreprise, notre DJ professionnel s'adapte à vos envies pour créer une ambiance unique et festive.",
    image: icone1,
    link: "/dj",
  },
  {
    title: "Location son & éclairage",
    description:
      "Nous mettons notre savoir-faire à votre service pour vous proposer des solutions adaptées à vos besoins, que ce soit pour un mariage, un concert, un festival, une soirée privée ou un événement d’entreprise.",
    image: icone2,
    link: "/sono",
  },
  {
    title: "Montage & transport",
    description:
      "Assurez-vous que votre événement se déroule sans accroc grâce à notre service de montage et transport professionnel. Que vous organisiez une fête privée, un concert, nous prenons en charge toute la logistique pour garantir une installation rapide, efficace et sécurisée.",
    image: icone3,
    link: "/services",
  },
];

function Services() {
  return (
    <div className={styles.containerServices}>
      {servicesData.map((service, index) => (
        <div className={styles.service} key={index}>
          <img src={service.image} alt={service.title}></img>
          <span>{service.title}</span>
          <span className={styles.servicetxt}>{service.description}</span>
          <Link to={service.link} onClick={() => window.scrollTo(0, 0)}>
            <button>En savoir plus</button>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Services;
