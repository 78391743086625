import service from "../../assets/images/Images/service.jpg";
import Materiels from "../../components/materiels/Materiels";
import style from "../sono/Sono.module.scss";
import flightCase from "../../assets/images/Images/flightCase.jpg";
import Realisation from "../../components/realisation/Realisation";
import Services from "../../components/servic/Services";
import Valeurs from "../../components/valeurs/Valeurs";

function Servic() {
  return (
    <div className={style.containerSono}>
      <div className={style.containerImg}>
        <img src={service} alt="DJ" />
      </div>
      <div className={style.descContainer}>
        <div className={style.descMat}>
          <h2>Un Service Client Personnalisé pour des Moments Mémorables</h2>
          <p>
            Chez Sidéral, nous mettons notre expertise technique et notre
            équipement au service de vos événements en province du Luxembourg et
            au Luxembourg ainsi qu'en France (Rayon de 100KM). Spécialisés dans
            la location de matériel de scène, sonorisation, éclairage, et
            animation, nous offrons un accompagnement complet pour assurer le
            succès de votre événement. Avec Sidéral, bénéficiez d'une solution
            clé en main, de l'installation au transport, en passant par une
            logistique parfaitement maîtrisée. Nous créons des événements sur
            mesure, adaptés aux exigences et au style de chaque client. Que vous
            organisiez une réception intime, une fête privée, un concert, ou un
            événement d'entreprise, notre savoir-faire et notre matériel
            professionnel garantiront une ambiance unique et réussie. Faites
            confiance à Sidéral pour transformer votre vision en réalité et
            créer des moments mémorables grâce à une technique irréprochable et
            un service sur mesure.
          </p>
        </div>
        <div className={style.imgMat}>
          <img src={flightCase} alt="" />
        </div>
      </div>
      <Materiels />
      <Realisation />
      <Services />
      <Valeurs />
    </div>
  );
}

export default Servic;
