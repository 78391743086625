import React from "react";
import style from "./PrivacyPolicy.module.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className={style.privacyPolicyContainer}>
      <h1>Politique de Confidentialité</h1>
      <section className={style.section}>
        <h2>Introduction</h2>
        <p>
          Nous attachons une grande importance à la protection de vos données
          personnelles. Cette politique de confidentialité explique comment nous
          collectons, utilisons, et protégeons les informations que vous nous
          fournissez.
        </p>
      </section>

      <section className={style.section}>
        <h2>Informations collectées</h2>
        <ul>
          <li>Nom et prénom</li>
          <li>Adresse e-mail</li>
          <li>Numéro de téléphone</li>
          <li>Informations relatives à l'événement (date, lieu, type, etc.)</li>
        </ul>
      </section>

      <section className={style.section}>
        <h2>Utilisation des données</h2>
        <p>Les informations que vous nous fournissez sont utilisées pour :</p>
        <ul>
          <li>Répondre à vos demandes</li>
          <li>Organiser les événements selon vos besoins</li>
          <li>Améliorer nos services et notre communication</li>
        </ul>
      </section>

      <section className={style.section}>
        <h2>Partage des données</h2>
        <p>
          Nous ne partageons vos données personnelles avec des tiers que si cela
          est nécessaire pour fournir le service demandé, ou si nous sommes
          légalement tenus de le faire.
        </p>
      </section>

      <section className={style.section}>
        <h2>Sécurité</h2>
        <p>
          Nous mettons en œuvre des mesures techniques et organisationnelles
          pour protéger vos données personnelles contre tout accès non autorisé,
          perte, destruction ou modification.
        </p>
      </section>

      <section className={style.section}>
        <h2>Vos droits</h2>
        <p>
          Vous avez le droit d'accéder à vos données, de les corriger, de
          demander leur suppression ou de vous opposer à leur traitement. Pour
          exercer ces droits, veuillez nous contacter à l'adresse suivante :{" "}
          <strong>contact@votreentreprise.com</strong>.
        </p>
      </section>

      <section className={style.section}>
        <h2>Modifications</h2>
        <p>
          Nous pouvons mettre à jour cette politique de confidentialité de temps
          en temps. Nous vous encourageons à consulter cette page régulièrement
          pour rester informé.
        </p>
      </section>

      <footer className={style.footer}>
        <p>
          Si vous avez des questions ou des préoccupations concernant cette
          politique, veuillez nous contacter.
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
