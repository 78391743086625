import style from "./Sono.module.scss";
import light from "../../assets/images/Images/light.jpg";
import Materiels from "../../components/materiels/Materiels";
import imgNexus from "../../assets/images/matNexus.jpg";
import Realisation from "../../components/realisation/Realisation";
import Services from "../../components/servic/Services";
import Valeurs from "../../components/valeurs/Valeurs";
import Gallery from "../../components/gallery/Gallery";

function Sono() {
  return (
    <div className={style.containerSono}>
      <div className={style.containerImg}>
        <img src={light} alt="DJ" />
      </div>
      <div className={style.descContainer}>
        <div className={style.descMat}>
          <h2>La Technique au Service de Votre Événement</h2>
          <p>
            Sidéral vous accompagne dans la réussite de vos événements en
            province du Luxembourg et au Luxembourg ainsi qu' en France ( Rayon
            de 100KM ), avec des services de location de matériel de scène,
            sonorisation, éclairage, et animation. Nous proposons une solution
            complète qui inclut le montage, le transport, et toute la logistique
            nécessaire, pour des événements sur mesure, parfaitement adaptés à
            vos besoins. Que ce soit pour une petite réception ou un grand
            événement, notre expertise et notre matériel professionnel feront de
            votre projet une réussite
          </p>
        </div>
        <div className={style.imgMat}>
          <img src={imgNexus} alt="" />
        </div>
      </div>
      <Materiels />
      <Realisation />
      <Services />
      <Gallery />
      <Valeurs />
    </div>
  );
}

export default Sono;
