import style from "./Footer.module.scss";
import phone from "../../assets/images/Icones/phone.png";
import mail from "../../assets/images/Icones/mail.png";
import form from "../../assets/images/Icones/form.png";
import logo from "../../assets/images/logoSideral.jpg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={style.containerFooter}>
      <div className={style.containerTopInfo}>
        <div className={style.containerinfo}>
          <img src={phone} alt="phone" />
          <div className={style.info}>
            <div>TELEPHONE</div>
            <p>+32 475/73.95.55</p>
          </div>
        </div>
        <div className={style.separator}></div>
        <div className={style.containerinfo}>
          <img src={mail} alt="mail" />
          <div className={style.info}>
            <div>E-MAIL</div>
            <p>patrick.wala@skynet.be</p>
          </div>
        </div>
        <div className={style.separator}></div>
        <div className={style.containerinfo}>
          <img src={form} alt="form" />
          <div className={style.info}>
            <div>FORMULAIRE</div>
            <Link to={`contact`} onClick={() => window.scrollTo(0, 0)}>
              <button>Contact</button>
            </Link>
          </div>
        </div>
      </div>
      <div className={style.containerInside}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={style.navigation}>
          <div>Navigation</div>
          <ul>
            <li>
              <Link to={`/`} onClick={() => window.scrollTo(0, 0)}>
                <button>Accueil</button>
              </Link>
            </li>
            <li>
              <Link to={`dj`} onClick={() => window.scrollTo(0, 0)}>
                <button>DJ</button>
              </Link>
            </li>
            <li>
              <Link to={`sono`} onClick={() => window.scrollTo(0, 0)}>
                <button>Sonorisation / Eclairage</button>
              </Link>
            </li>
            <li>
              <Link to={`services`} onClick={() => window.scrollTo(0, 0)}>
                <button>Services</button>
              </Link>
            </li>
            <li>
              <Link to={`contact`} onClick={() => window.scrollTo(0, 0)}>
                <button>Contact</button>
              </Link>
            </li>
            <li>
              <Link to={`privacy`} onClick={() => window.scrollTo(0, 0)}>
                <button>Politique de confidentialité</button>
              </Link>
            </li>
          </ul>
        </div>
        <div className={style.description}>
          <div>QUI SOMMES-NOUS ?</div>
          <p>
            Sidéral propose la location de matériel pour événements (scène, son,
            lumière, podium, couverture de scène, animation) en province du
            Luxembourg et au Luxembourg. Nous offrons un service complet
            incluant montage, transport et logistique pour tout type
            d’événement, avec des solutions sur mesure adaptées à vos besoins.
          </p>
        </div>
        <div className={style.socialnetwork}>
          <div
            className="fb-page"
            data-href="https://www.facebook.com/profile.php?id=100063690212724"
            data-width="300"
            data-height="400"
            data-small-header="false"
            data-adapt-container-width="false"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/profile.php?id=100063690212724"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/profile.php?id=100063690212724">
                Sono Sideral
              </a>
            </blockquote>
          </div>
        </div>
      </div>
      <div className={style.containerBottomCopyright}>
        <p>Copyright © 2024 - Tous droits réservés.</p>
        <Link to={`LegalMentions `} onClick={() => window.scrollTo(0, 0)}>
          <button>Mentions légales</button>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
