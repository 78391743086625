import mariage from "../../assets/images/Icones/mariage.png";
import eventPro from "../../assets/images/Icones/eventPro.png";
import birthday from "../../assets/images/Icones/birthday.png";
import culture from "../../assets/images/Icones/culture.png";
import festival from "../../assets/images/Icones/festival.png";
import lancement from "../../assets/images/Icones/lancement.png";
import cocktail from "../../assets/images/Icones/private.png";
import style from "./Realisation.module.scss";

function Realisation() {
  return (
    <div className={style.realisationsContainer}>
      <div className={style.iconRow}>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={mariage} alt="mariage" />
          </div>

          <h3>Mariage</h3>
          <p>
            Création d'une ambiance inoubliable avec une sonorisation de
            qualité, un éclairage personnalisé, et un DJ pour animer chaque
            moment clé de la soirée.
          </p>
        </div>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={eventPro} alt="eventPro" />
          </div>

          <h3>Evénements d'entreprise</h3>
          <p>
            Gestion de l’audiovisuel pour séminaires, team-building, et
            conférences. Installation de systèmes son et lumière, et DJ pour les
            moments festifs.
          </p>
        </div>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={birthday} alt="birthday" />
          </div>

          <h3>Anniversaires </h3>
          <p>
            Ambiance musicale adaptée, éclairage festif, et DJ pour des fêtes
            d’anniversaire inoubliables, pour petits et grands.
          </p>
        </div>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={culture} alt="culture" />
          </div>

          <h3>Evénements culturels</h3>
          <p>
            Sonorisation et éclairage pour concerts, spectacles, et expositions,
            pour une expérience immersive de qualité.
          </p>
        </div>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={cocktail} alt="cocktail" />
          </div>

          <h3>Soirées privées</h3>
          <p>
            Services DJ, sonorisation, et lumière pour des soirées privées
            personnalisées, en fonction des goûts et de l'ambiance souhaitée.
          </p>
        </div>
        <div className={style.realisationContainer}>
          <div className={style.iconContainer}>
            <img src={lancement} alt="lancement" />
          </div>
          <h3>Lancements de produits</h3>
          <p>
            Création d’une atmosphère dynamique avec éclairage, sonorisation et
            DJ pour des soirées de lancement percutantes.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Realisation;
