import styles from "./Homepage.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import image1 from "./../../assets/images/Carousel/DJ1.jpg";
import image2 from "./../../assets/images/Carousel/DJ2.jpg";
import image3 from "./../../assets/images/Carousel/DJ3.jpg";
import image4 from "./../../assets/images/Carousel/DJ4.jpg";
import image5 from "./../../assets/images/Carousel/DJ5.jpg";
import image6 from "./../../assets/images/Carousel/DJ6.jpg";
import Services from "../../components/servic/Services";
import { Link } from "react-router-dom";
import Valeurs from "../../components/valeurs/Valeurs";

function Homepage() {
  return (
    <>
      <div className={styles.test}>
        <Carousel
          className={` ${styles.test1}`}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          autoPlay
          infiniteLoop
        >
          <div>
            <img src={image1} alt="" />
          </div>
          <div>
            <img src={image2} alt="" />
          </div>
          {/* <div>
            <img src={image3} alt="" />
          </div> */}
          <div>
            <img src={image4} alt="" />
          </div>
          {/* <div>
            <img src={image5} alt="" />
          </div> */}
          <div>
            <img src={image6} alt="" />
          </div>
        </Carousel>
        <div className={styles.headerContainer}>
          <div className={`${styles.txt_crsl}`}>
            <div className={styles.services}>
              DJ <br />
              LOCATION SON & ÉCLAIRAGE <br />
              MONTAGE & TRANSPORT
            </div>
            <div className={styles.separator}></div>
            <div className={styles.slogan}>
              <span className={styles.typewriter}>
                Son, lumière, ambiance : Nous créons vos plus beaux souvenirs !
              </span>
            </div>
            <div className={styles.ctaButton}>
              <Link to={`sono`} onClick={() => window.scrollTo(0, 0)}>
                <button>En savoir plus</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.devis}>
        <div className={styles.budget}> On s'adapte à votre budget !</div>
        <Link to={`contact`}>
          <button className={styles.btn_devis}> DEMANDER UN DEVIS</button>
        </Link>
      </div>
      <Services />
      <Valeurs />
    </>
  );
}

export default Homepage;
