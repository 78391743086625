import style from "./../Materiel.module.scss";

function Other() {
  return (
    <div className={style.descriptMat}>
      <li>Lanceur confetti</li>
      <li>CO²</li>
      <li>Etincelle</li>
      <li>Fumée lourde, fumée hazer</li>
    </div>
  );
}
export default Other;
