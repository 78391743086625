// OK 23/11/2024

import styles from "./Valeurs.module.scss";
import creativite from "../../assets/images/Icones/creativite.png";
import pro from "../../assets/images/Icones/pro.png";
import ecoute from "../../assets/images/Icones/ecoute.png";
import fiable from "../../assets/images/Icones/fiable.png";
import satisfaction from "../../assets/images/Icones/satisfaction.png";

const valeursData = [
  {
    id: 1,
    icon: creativite,
    title: "Passion & Créativité",
    description:
      "Chez Sidéral, chaque projet est une nouvelle occasion de laisser libre cours à notre passion. Nous mettons un point d'honneur à insuffler créativité et innovation dans tous les aspects de notre travail.",
  },
  // {
  //   id: 2,
  //   icon: pro,
  //   title: "Professionnalisme & Excellence",
  //   description:
  //     "Avec une équipe expérimentée et des équipements de pointe, nous assurons des prestations de haute qualité. Nous visons l'excellence à chaque étape pour garantir une expérience sans failles à nos clients.",
  // },
  {
    id: 3,
    icon: ecoute,
    title: "Ecoute & Personnalisation",
    description:
      "Chaque événement est unique, et c'est en écoutant attentivement vos besoins que nous pouvons créer des solutions sur mesure pour des événements qui vous ressemblent.",
  },
  {
    id: 4,
    icon: fiable,
    title: "Fiabilité & Rigueur",
    description:
      "Le respect des délais, la sécurité, et la précision technique sont au cœur de notre approche. Vous pouvez compter sur nous pour être présents à chaque instant clé.",
  },
  {
    id: 5,
    icon: satisfaction,
    title: "Engagement & Satisfaction",
    description:
      "Notre mission est de rendre vos événements inoubliables. Nous nous engageons à dépasser vos attentes pour transformer vos idées en réalité.",
  },
];

function Valeurs() {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>NOS VALEURS</h2>
      <div className={styles.titleBar}></div>
      <div className={styles.containerValeurs}>
        {valeursData.map((valeur) => (
          <div key={valeur.id} className={styles.card}>
            <div className={styles.cardTop}>
              <div className={styles.icon}>
                <img src={valeur.icon} alt={valeur.title} />
              </div>
              <h3 className={styles.titleCard}>{valeur.title}</h3>
            </div>
            <p className={styles.text}>{valeur.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Valeurs;
