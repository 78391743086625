import { useState } from "react";
import style from "./Materiel.module.scss";
import Sonorisation from "./components/Sonorisation";
import Lumiere from "./components/Lumiere";
import Scene from "./components/Scene";
import Other from "./components/Other";
import speaker from "./../../assets/images/Icones/speaker.png";
import light from "./../../assets/images/Icones/light.png";
import scene from "./../../assets/images/Icones/scene.png";
import other from "./../../assets/images/Icones/other.png";

function Materiels() {
  const [typeNumberMaterial, setTypeNumberMaterial] = useState(1);

  return (
    <div className={style.container}>
      <div className={style.materialContainer}>
        <div className={style.buttonsContainer}>
          <div className={style.buttonContainer}>
            <button
              className={
                typeNumberMaterial === 1 ? style.buttonActif : style.buttonOff
              }
              onClick={() => setTypeNumberMaterial(1)}
            >
              <img src={speaker} alt="Speaker" />
              Sonorisation
            </button>
          </div>
          <div className={style.buttonContainer}>
            <button
              className={
                typeNumberMaterial === 2 ? style.buttonActif : style.buttonOff
              }
              onClick={() => setTypeNumberMaterial(2)}
            >
              <img src={light} alt="Light" />
              Lumière
            </button>
          </div>
          <div className={style.buttonContainer}>
            <button
              className={
                typeNumberMaterial === 3 ? style.buttonActif : style.buttonOff
              }
              onClick={() => setTypeNumberMaterial(3)}
            >
              <img src={scene} alt="Scene" />
              Scène
            </button>
          </div>
          <div className={style.buttonContainer}>
            <button
              className={
                typeNumberMaterial === 4 ? style.buttonActif : style.buttonOff
              }
              onClick={() => setTypeNumberMaterial(4)}
            >
              <img src={other} alt="Other" />
              Autres
            </button>
          </div>
        </div>
        <div className={style.infoContainer}>
          {typeNumberMaterial === 1 && <Sonorisation />}
          {typeNumberMaterial === 2 && <Lumiere />}
          {typeNumberMaterial === 3 && <Scene />}
          {typeNumberMaterial === 4 && <Other />}
        </div>
      </div>
    </div>
  );
}

export default Materiels;
