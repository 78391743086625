import style from "./Dj.module.scss";
import patrick from "./../../assets/images/walaPatrick.jpg";
import dj from "../../assets/images/Images/dj.jpg";
import Services from "../../components/servic/Services";
import Realisation from "../../components/realisation/Realisation";
import Valeurs from "../../components/valeurs/Valeurs";
import Gallery from "../../components/gallery/Gallery";

function Dj() {
  return (
    <div className={style.containerDj}>
      <div className={style.containerImg}>
        <img src={dj} alt="DJ" />
      </div>
      <div className={style.containerDjGray}>
        <h2>Patrick Wala</h2>
        <div className={style.presentation}>
          <img src={patrick} alt="Patrick Wala" />
          <p>
            Avec des années d'expérience derrière les platines, Patrick Wala est
            un passionné de musique qui sait comment faire vibrer une piste de
            danse. Spécialisé dans les morceaux intemporels des années 70 à
            aujourd'hui, il combine habilement les classiques et les hits
            actuels pour créer des ambiances uniques et mémorables. Patrick
            s'adapte à tous les styles et tous les publics, que ce soit pour une
            soirée rétro, une fête moderne ou un mix éclectique qui traverse les
            générations. Sa maîtrise du son, son sens du rythme et son énergie
            communicative font de lui le choix idéal pour des événements
            réussis. Toujours à l’écoute des envies de ses clients, Patrick
            prend soin de personnaliser chaque prestation, assurant que la
            musique reflète parfaitement l'esprit de chaque occasion.
          </p>
        </div>
      </div>
      <Realisation />
      <Services />
      <Gallery />
      <Valeurs />
    </div>
  );
}

export default Dj;
