import style from "./../Materiel.module.scss";

function Sonorisation() {
  return (
    <div className={style.descriptMat}>
      <li>Enceintes professionnelles (RCF)</li>
      <li>Discobar (Pioneer)</li>
      <li>Micro (Shure)</li>
    </div>
  );
}

export default Sonorisation;
