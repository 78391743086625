// OK 23/11/2024

import { NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logoSideral.jpg";
import burgerIcon from "../../assets/images/Icones/burger.png";
import { useState } from "react";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const links = [
    { path: "/", label: "Accueil" },
    { path: "/dj", label: "DJ" },
    { path: "/sono", label: "Sonorisation / Éclairage" },
    { path: "/services", label: "Services" },
    { path: "/contact", label: "Contact" },
  ];

  return (
    <header className={`${styles.header} ${menuOpen ? styles.open : ""}`}>
      <div className={styles.logoContainer}>
        <NavLink to="/" className={styles.logo}>
          <img src={logo} alt="Logo Sideral" />
        </NavLink>
        <button
          className={styles.burgerButton}
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Menu"
        >
          <img src={burgerIcon} alt="Menu Burger" />
        </button>
      </div>
      <nav className={`${styles.nav} ${menuOpen ? styles.navOpen : ""}`}>
        {links.map(({ path, label }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
            onClick={() => setMenuOpen(false)} // Ferme le menu après un clic
          >
            {label}
          </NavLink>
        ))}
      </nav>
    </header>
  );
}

export default Header;
