import style from "./../Materiel.module.scss";

function Scene() {
  return (
    <div className={style.descriptMat}>
      <li>
        Couverture de scène (5,5m/3m, prochainement 6,5m / 4m), hauteur réglable
      </li>
      <li>Podium 15*2m² avec pieds réglables (indoor, outdoor)</li>
      <li>Support (Truss) </li>
    </div>
  );
}

export default Scene;
